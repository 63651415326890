/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BatchTaskAssignment } from './BatchTaskAssignment';
import {
    BatchTaskAssignmentFromJSON,
    BatchTaskAssignmentFromJSONTyped,
    BatchTaskAssignmentToJSON,
} from './BatchTaskAssignment';

/**
 * 
 * @export
 * @interface BatchJobTaskResponse
 */
export interface BatchJobTaskResponse {
    /**
     * 
     * @type {number}
     * @memberof BatchJobTaskResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BatchJobTaskResponse
     */
    arrayTaskId: number;
    /**
     * 
     * @type {number}
     * @memberof BatchJobTaskResponse
     */
    assignedToId?: number;
    /**
     * 
     * @type {BatchTaskAssignment}
     * @memberof BatchJobTaskResponse
     */
    assignedTo?: BatchTaskAssignment;
    /**
     * 
     * @type {string}
     * @memberof BatchJobTaskResponse
     */
    state: string;
    /**
     * 
     * @type {number}
     * @memberof BatchJobTaskResponse
     */
    exitCode?: number;
    /**
     * 
     * @type {Date}
     * @memberof BatchJobTaskResponse
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BatchJobTaskResponse
     */
    stop?: Date;
}

/**
 * Check if a given object implements the BatchJobTaskResponse interface.
 */
export function instanceOfBatchJobTaskResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "arrayTaskId" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function BatchJobTaskResponseFromJSON(json: any): BatchJobTaskResponse {
    return BatchJobTaskResponseFromJSONTyped(json, false);
}

export function BatchJobTaskResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchJobTaskResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'arrayTaskId': json['array_task_id'],
        'assignedToId': !exists(json, 'assigned_to_id') ? undefined : json['assigned_to_id'],
        'assignedTo': !exists(json, 'assigned_to') ? undefined : BatchTaskAssignmentFromJSON(json['assigned_to']),
        'state': json['state'],
        'exitCode': !exists(json, 'exit_code') ? undefined : json['exit_code'],
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'stop': !exists(json, 'stop') ? undefined : (new Date(json['stop'])),
    };
}

export function BatchJobTaskResponseToJSON(value?: BatchJobTaskResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'array_task_id': value.arrayTaskId,
        'assigned_to_id': value.assignedToId,
        'assigned_to': BatchTaskAssignmentToJSON(value.assignedTo),
        'state': value.state,
        'exit_code': value.exitCode,
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'stop': value.stop === undefined ? undefined : (value.stop.toISOString()),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const JobStateEnum = {
    Pending: 'pending',
    Queued: 'queued',
    Running: 'running',
    Done: 'done',
    DoneErrors: 'done (errors)'
} as const;
export type JobStateEnum = typeof JobStateEnum[keyof typeof JobStateEnum];


export function JobStateEnumFromJSON(json: any): JobStateEnum {
    return JobStateEnumFromJSONTyped(json, false);
}

export function JobStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStateEnum {
    return json as JobStateEnum;
}

export function JobStateEnumToJSON(value?: JobStateEnum | null): any {
    return value as any;
}


/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UsageBreakdownItem } from './UsageBreakdownItem';
import {
    UsageBreakdownItemFromJSON,
    UsageBreakdownItemFromJSONTyped,
    UsageBreakdownItemToJSON,
} from './UsageBreakdownItem';

/**
 * 
 * @export
 * @interface WorkspaceProgramUsageSchema
 */
export interface WorkspaceProgramUsageSchema {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceProgramUsageSchema
     */
    workspaceCreditsSpent: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceProgramUsageSchema
     */
    workspaceCreditsUsagePercent: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceProgramUsageSchema
     */
    workspaceCreditsUserSpent: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceProgramUsageSchema
     */
    workspaceCreditsUserUsagePercent: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceProgramUsageSchema
     */
    workspaceCreditsUserLimit: number;
    /**
     * 
     * @type {Array<UsageBreakdownItem>}
     * @memberof WorkspaceProgramUsageSchema
     */
    workspaceUsageBreakdown: Array<UsageBreakdownItem>;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceProgramUsageSchema
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceProgramUsageSchema
     */
    endDate?: Date;
}

/**
 * Check if a given object implements the WorkspaceProgramUsageSchema interface.
 */
export function instanceOfWorkspaceProgramUsageSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "workspaceCreditsSpent" in value;
    isInstance = isInstance && "workspaceCreditsUsagePercent" in value;
    isInstance = isInstance && "workspaceCreditsUserSpent" in value;
    isInstance = isInstance && "workspaceCreditsUserUsagePercent" in value;
    isInstance = isInstance && "workspaceCreditsUserLimit" in value;
    isInstance = isInstance && "workspaceUsageBreakdown" in value;

    return isInstance;
}

export function WorkspaceProgramUsageSchemaFromJSON(json: any): WorkspaceProgramUsageSchema {
    return WorkspaceProgramUsageSchemaFromJSONTyped(json, false);
}

export function WorkspaceProgramUsageSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceProgramUsageSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspaceCreditsSpent': json['workspace_credits_spent'],
        'workspaceCreditsUsagePercent': json['workspace_credits_usage_percent'],
        'workspaceCreditsUserSpent': json['workspace_credits_user_spent'],
        'workspaceCreditsUserUsagePercent': json['workspace_credits_user_usage_percent'],
        'workspaceCreditsUserLimit': json['workspace_credits_user_limit'],
        'workspaceUsageBreakdown': ((json['workspace_usage_breakdown'] as Array<any>).map(UsageBreakdownItemFromJSON)),
        'startDate': !exists(json, 'start_date') ? undefined : (new Date(json['start_date'])),
        'endDate': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'])),
    };
}

export function WorkspaceProgramUsageSchemaToJSON(value?: WorkspaceProgramUsageSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspace_credits_spent': value.workspaceCreditsSpent,
        'workspace_credits_usage_percent': value.workspaceCreditsUsagePercent,
        'workspace_credits_user_spent': value.workspaceCreditsUserSpent,
        'workspace_credits_user_usage_percent': value.workspaceCreditsUserUsagePercent,
        'workspace_credits_user_limit': value.workspaceCreditsUserLimit,
        'workspace_usage_breakdown': ((value.workspaceUsageBreakdown as Array<any>).map(UsageBreakdownItemToJSON)),
        'start_date': value.startDate === undefined ? undefined : (value.startDate.toISOString().substring(0,10)),
        'end_date': value.endDate === undefined ? undefined : (value.endDate.toISOString().substring(0,10)),
    };
}


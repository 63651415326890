import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SelectAutocomplete } from "./SelectAutocomplete";
import { useValidScopes } from "../crud/user/hooks";
import { OrganizationScopeSchema } from "../api-client";
import { useScopedContext } from "../shared-components/ScopeSelector";
import { useOrganization } from "../crud/organizations/hooks";
import { Box, Skeleton } from "@mui/material";

type OrganizationSelectorProps = {
  onChange: (org: OrganizationScopeSchema | undefined) => void;
};

type OrgInnerProps = OrganizationSelectorProps & {
  defaultOrg?: OrganizationScopeSchema;
};

const OrganizationSelectorInner = ({
  onChange,
  defaultOrg,
}: OrgInnerProps): React.ReactElement => {
  const [, setGlobalScope] = useScopedContext();
  const [orgFilter, setOrgFilter] = useState<string>("");
  const { data: scopes, isLoading } = useValidScopes({
    organization: orgFilter,
  });
  const orgs = useMemo(() => scopes?.organizations || [], [scopes]);
  const [selectedOrg, setSelectedOrg] = useState<
    OrganizationScopeSchema | undefined
  >(defaultOrg);

  // hacky way to get full information about the org if all we have is name (from URL query params)
  const { data: matchScopes } = useValidScopes({
    organization: selectedOrg?.name || "NOTHING-TO-MATCH",
  });
  const matchOrgs = useMemo(
    () => matchScopes?.organizations || [],
    [matchScopes],
  );

  const orgRef = useRef<OrganizationScopeSchema | undefined>(undefined);
  const _onChange = useCallback(
    (e: OrganizationScopeSchema | undefined) => {
      if (orgRef.current !== e) {
        orgRef.current = e;
        onChange(e);
      }
    },
    [onChange],
  );
  useEffect(() => {
    if (!selectedOrg && orgs && orgs.length === 1) {
      setSelectedOrg(orgs[0]);
    } else if (selectedOrg?.name && !selectedOrg.id && orgs) {
      // URL params might just have the org name, but org id is used in queries, so update "selected org"
      const match = matchOrgs.filter((org) => org.name === selectedOrg.name);
      if (match.length === 1) {
        setSelectedOrg(match[0]);
      }
    }
  }, [orgs, selectedOrg, setSelectedOrg, matchOrgs]);
  useEffect(() => {
    _onChange(selectedOrg);
  }, [selectedOrg, _onChange]);
  return (
    <SelectAutocomplete
      isLoading={isLoading}
      placeHolder="Search for an organization"
      options={scopes?.organizations || []}
      onChange={(e) => {
        setGlobalScope(e);
        setSelectedOrg(e);
        onChange(e);
      }}
      filterValueChanged={(e) => setOrgFilter(e)}
      value={selectedOrg}
      renderOption={(option) => (
        <span>
          Organization:{" "}
          <Box
            component="span"
            sx={{ fontWeight: "bold", textTransform: "none" }}
          >
            {option.name}
          </Box>
        </span>
      )}
    />
  );
};

export const OrganizationSelector = ({
  onChange,
}: OrganizationSelectorProps): React.ReactElement => {
  const [globalScope] = useScopedContext();

  // Here's a hack to get the organizationId.
  // It would be better/simpler of the organizationId were reliably in the globalScope,
  // but I don't know how to make that happen.
  //
  // (In case this helps anyone else: when globalScope.type is "account",
  // globalScope seems to include organizationId iff the scope was set by selecting
  // a workspace in the dropdown, but NOT when the globalScope came via URL.
  // This hack wouldn't be necessary if globalScope *always* included organizationId,
  // but I tried to make that happen and gave up.)
  const { data: scopes } = useValidScopes({});

  const workspaceName =
    globalScope.type === "account" ? globalScope.name : undefined;
  const matchingScope = scopes?.accounts.find(
    (item) => item.name === workspaceName,
  );
  const organizationId =
    (globalScope.type === "account" && globalScope.organizationId) ||
    matchingScope?.organizationId;

  const { data: org, isSuccess } = useOrganization(organizationId);
  return (
    <>
      {globalScope.type === "account" && organizationId && !isSuccess && (
        <Skeleton />
      )}
      {globalScope.type === "account" && organizationId && isSuccess && (
        <OrganizationSelectorInner
          onChange={onChange}
          defaultOrg={{ name: org.name, id: org.id, type: "organization" }}
        />
      )}
      {(globalScope.type !== "account" || !organizationId) && (
        <OrganizationSelectorInner
          onChange={onChange}
          defaultOrg={
            globalScope.type === "organization" ? globalScope : undefined
          }
        />
      )}
    </>
  );
};

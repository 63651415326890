/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSingleScriptClusterSchema
 */
export interface CreateSingleScriptClusterSchema {
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    script?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    scriptUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    softwareEnvName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    container?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSingleScriptClusterSchema
     */
    python?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    workspace?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    schedulerVmTypes?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSingleScriptClusterSchema
     */
    workerVmTypes?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSingleScriptClusterSchema
     */
    workers?: number;
}

/**
 * Check if a given object implements the CreateSingleScriptClusterSchema interface.
 */
export function instanceOfCreateSingleScriptClusterSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateSingleScriptClusterSchemaFromJSON(json: any): CreateSingleScriptClusterSchema {
    return CreateSingleScriptClusterSchemaFromJSONTyped(json, false);
}

export function CreateSingleScriptClusterSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSingleScriptClusterSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'script': !exists(json, 'script') ? undefined : json['script'],
        'scriptUrl': !exists(json, 'script_url') ? undefined : json['script_url'],
        'softwareEnvName': !exists(json, 'software_env_name') ? undefined : json['software_env_name'],
        'container': !exists(json, 'container') ? undefined : json['container'],
        'python': !exists(json, 'python') ? undefined : json['python'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'workspace': !exists(json, 'workspace') ? undefined : json['workspace'],
        'schedulerVmTypes': !exists(json, 'scheduler_vm_types') ? undefined : json['scheduler_vm_types'],
        'workerVmTypes': !exists(json, 'worker_vm_types') ? undefined : json['worker_vm_types'],
        'workers': !exists(json, 'workers') ? undefined : json['workers'],
    };
}

export function CreateSingleScriptClusterSchemaToJSON(value?: CreateSingleScriptClusterSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'script': value.script,
        'script_url': value.scriptUrl,
        'software_env_name': value.softwareEnvName,
        'container': value.container,
        'python': value.python,
        'name': value.name,
        'workspace': value.workspace,
        'scheduler_vm_types': value.schedulerVmTypes,
        'worker_vm_types': value.workerVmTypes,
        'workers': value.workers,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsageBreakdownItem
 */
export interface UsageBreakdownItem {
    /**
     * 
     * @type {string}
     * @memberof UsageBreakdownItem
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UsageBreakdownItem
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof UsageBreakdownItem
     */
    hours: number;
}

/**
 * Check if a given object implements the UsageBreakdownItem interface.
 */
export function instanceOfUsageBreakdownItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "hours" in value;

    return isInstance;
}

export function UsageBreakdownItemFromJSON(json: any): UsageBreakdownItem {
    return UsageBreakdownItemFromJSONTyped(json, false);
}

export function UsageBreakdownItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsageBreakdownItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'price': json['price'],
        'hours': json['hours'],
    };
}

export function UsageBreakdownItemToJSON(value?: UsageBreakdownItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'price': value.price,
        'hours': value.hours,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JobStateEnum } from './JobStateEnum';
import {
    JobStateEnumFromJSON,
    JobStateEnumFromJSONTyped,
    JobStateEnumToJSON,
} from './JobStateEnum';

/**
 * 
 * @export
 * @interface BatchJobSummaryResponse
 */
export interface BatchJobSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof BatchJobSummaryResponse
     */
    id: number;
    /**
     * 
     * @type {JobStateEnum}
     * @memberof BatchJobSummaryResponse
     */
    state: JobStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof BatchJobSummaryResponse
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof BatchJobSummaryResponse
     */
    started?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BatchJobSummaryResponse
     */
    completed?: Date;
    /**
     * 
     * @type {number}
     * @memberof BatchJobSummaryResponse
     */
    clusterId?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchJobSummaryResponse
     */
    clusterState?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchJobSummaryResponse
     */
    workspaceName?: string;
    /**
     * 
     * @type {number}
     * @memberof BatchJobSummaryResponse
     */
    approximateCloudTotalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof BatchJobSummaryResponse
     */
    userCommand: string;
    /**
     * 
     * @type {number}
     * @memberof BatchJobSummaryResponse
     */
    nTasks: number;
    /**
     * 
     * @type {number}
     * @memberof BatchJobSummaryResponse
     */
    nTasksSucceeded: number;
    /**
     * 
     * @type {number}
     * @memberof BatchJobSummaryResponse
     */
    nTasksFailed: number;
}

/**
 * Check if a given object implements the BatchJobSummaryResponse interface.
 */
export function instanceOfBatchJobSummaryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "userCommand" in value;
    isInstance = isInstance && "nTasks" in value;
    isInstance = isInstance && "nTasksSucceeded" in value;
    isInstance = isInstance && "nTasksFailed" in value;

    return isInstance;
}

export function BatchJobSummaryResponseFromJSON(json: any): BatchJobSummaryResponse {
    return BatchJobSummaryResponseFromJSONTyped(json, false);
}

export function BatchJobSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchJobSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': JobStateEnumFromJSON(json['state']),
        'created': (new Date(json['created'])),
        'started': !exists(json, 'started') ? undefined : (new Date(json['started'])),
        'completed': !exists(json, 'completed') ? undefined : (new Date(json['completed'])),
        'clusterId': !exists(json, 'cluster_id') ? undefined : json['cluster_id'],
        'clusterState': !exists(json, 'cluster_state') ? undefined : json['cluster_state'],
        'workspaceName': !exists(json, 'workspace_name') ? undefined : json['workspace_name'],
        'approximateCloudTotalCost': !exists(json, 'approximate_cloud_total_cost') ? undefined : json['approximate_cloud_total_cost'],
        'userCommand': json['user_command'],
        'nTasks': json['n_tasks'],
        'nTasksSucceeded': json['n_tasks_succeeded'],
        'nTasksFailed': json['n_tasks_failed'],
    };
}

export function BatchJobSummaryResponseToJSON(value?: BatchJobSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'state': JobStateEnumToJSON(value.state),
        'created': (value.created.toISOString()),
        'started': value.started === undefined ? undefined : (value.started.toISOString()),
        'completed': value.completed === undefined ? undefined : (value.completed.toISOString()),
        'cluster_id': value.clusterId,
        'cluster_state': value.clusterState,
        'workspace_name': value.workspaceName,
        'approximate_cloud_total_cost': value.approximateCloudTotalCost,
        'user_command': value.userCommand,
        'n_tasks': value.nTasks,
        'n_tasks_succeeded': value.nTasksSucceeded,
        'n_tasks_failed': value.nTasksFailed,
    };
}


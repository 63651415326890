import React, { useContext } from "react";
import { Alert, Divider, Stack, Typography } from "@mui/material";
import { SoftwareSpecContext } from "../../crud/software/context";
import { CodeBlock } from "../CodeBlock";
import { SoftwareSpecTable } from "./spec";

export const SoftwareSpecificationDetails = (): React.ReactElement => {
  const spec = useContext(SoftwareSpecContext);
  return (
    <Stack spacing={2}>
      {!!spec.rawPip?.length && (
        <>
          <Typography variant="h2">requirements.txt:</Typography>
          <Divider />
          <Typography variant="body1">
            <CodeBlock snippet={spec.rawPip.join("\n")} language={"python"} />
          </Typography>
        </>
      )}
      {!!spec.rawConda && (
        <>
          <Typography variant="h2">environment.yml:</Typography>
          <Divider />
          <Typography>
            <CodeBlock snippet={spec.rawConda} language={"python"} />
          </Typography>
        </>
      )}
      {!!spec.packages.length && (
        <Stack spacing={2}>
          {!spec.rawPip && (
            <>
              <Typography variant="h2">Fully Resolved:</Typography>
              <Divider />
            </>
          )}
          {spec.rawPip && (
            <Alert severity="warning">
              We automatically determined your python version and added it
            </Alert>
          )}
          <SoftwareSpecTable />
        </Stack>
      )}
    </Stack>
  );
};

import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import { StyledAlert } from "../Alerts/StyledAlert";
import { styled } from "@mui/material";
import { BannersContext } from "../../crud/banners/context";
import { Banner, BannerLevel } from "../../crud/banners/types";
import { Urls } from "../../domain/urls";
import { useLocation } from "react-router-dom";

/* 
we iteratively create banners from our database
so here we need to shim in our styles 
*/
const StyledRoot = styled("div")(({ theme }) => {
  return {
    "& a": {
      color: theme.palette.primary.main,
      // TODO Fabio: Fix MuiLink issue
      // ...theme.overrides!.MuiLink!.root,
    },
    "& a:hover": {
      // TODO Fabio: Fix MuiLink issue
      // ...theme.overrides!.MuiLink!.root!["&.MuiLink-root:hover"],
    },
    "& p": {
      marginTop: "unset",
      marginBottom: "unset",
      ...theme.typography.body1,
    },
  };
});

export const BannerAlerts = (): React.ReactElement | null => {
  const banners = useContext(BannersContext);
  const { pathname } = useLocation();
  // only show "info" level banners on the cluster dashboard page
  const showInfoLevel = pathname.substring(1) === Urls.Clusters;
  const bannersToShow = banners.filter(
    (banner) => showInfoLevel || banner.level !== BannerLevel.Info,
  );

  return bannersToShow.length > 0 ? (
    <StyledRoot>
      {bannersToShow.map((banner: Banner) => (
        <StyledAlert
          key={banner.id}
          severity={banner.level}
          title={<ReactMarkdown>{banner.title}</ReactMarkdown>}
          message={<ReactMarkdown>{banner.message}</ReactMarkdown>}
        />
      ))}
    </StyledRoot>
  ) : null;
};

import { Alert, AlertColor, AlertTitle } from "@mui/material";
import React from "react";

type Props = {
  title: string | React.ReactNode;
  message: React.ReactNode;
  severity: AlertColor;
  onClose?: () => void;
};

export const StyledAlert = ({
  title,
  message,
  severity,
  onClose,
}: Props): React.ReactElement => {
  return (
    <Alert severity={severity} onClose={onClose}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
};

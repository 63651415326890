import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Box,
  Chip,
  Tooltip,
  InputAdornment,
  Stack,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import syntaxTheme from "../../../shared-components/syntaxTheme";
import syntaxHighlighterStyle from "../../../shared-components/syntaxHighlighterStyle";

import SearchIcon from "@mui/icons-material/Search";
import { DataGridPro, GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";
import { TimeRange } from "../types";
import {
  useClusterLogs,
  useClusterInfraEvents,
  previousTimeInterval,
} from "../../../crud/logs/hooks";
import { formatInTimeZone } from "date-fns-tz";
import {
  BooleanParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { useDebounce, useTimezone } from "../../../utils/hooks";
import { Download, MoreVert } from "@mui/icons-material";
import { timeRangeToString } from "./utils";
import { LogEntry } from "../../../crud/logs/types";
import { LogsHistogram } from "./charts/LogsHistogram";
import { BackendTypesEnum, ClusterFrontendSchema } from "../../../api-client";
import { CodeBlock } from "../../../shared-components/CodeBlock";

export const LogsView = ({
  clusterId,
  cluster,
  timeRange,
  setTimeRange,
  defaultLogTimeRange,
  defaultMetricsTimeRange,
  refAreaLeft,
  refAreaRight,
  setRefAreaLeft,
  setRefAreaRight,
  clusterIsRunning,
  zoomOut,
}: {
  clusterId: string;
  cluster?: ClusterFrontendSchema;
  timeRange?: TimeRange;
  setTimeRange: (timeRange: TimeRange | undefined) => void;
  defaultLogTimeRange?: TimeRange;
  defaultMetricsTimeRange?: TimeRange;
  refAreaLeft: number;
  refAreaRight: number;
  setRefAreaLeft: (refAreaLeft: number) => void;
  setRefAreaRight: (refAreaRight: number) => void;
  clusterIsRunning?: boolean;
  zoomOut: () => void;
}): React.ReactElement => {
  const liveUpdates = timeRange === defaultMetricsTimeRange && clusterIsRunning;
  const apiRef = useGridApiRef();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [highlightInstance, setHighlightInstance] = useState(-1);
  const [logHistoryComplete, setLogHistoryComplete] = useState(false);
  const [earliestTimestamp, setEarliestTimestamp] = useState<number>();

  const [displayTz] = useTimezone();

  const cloudProvider = cluster?.backendType;
  const canFetchClusterLogs = cloudProvider !== BackendTypesEnum.VmAzure;

  const showMenu = Boolean(menuAnchorEl);
  const [dask, setDask] = useQueryParam(
    "daskLogs",
    withDefault(BooleanParam, canFetchClusterLogs),
  );
  const [system, setSystem] = useQueryParam(
    "systemLogs",
    withDefault(BooleanParam, false),
  );

  const [showInfraEvents, setShowInfraEvents] = useQueryParam(
    "showLifecycle",
    withDefault(BooleanParam, true),
  );

  const [filterTimeRange, setFilterTimeRange] = useQueryParam(
    "filterLogTime",
    withDefault(BooleanParam, true),
  );

  const [filterPattern, setFilterPattern] = useQueryParam(
    "filterPattern",
    withDefault(StringParam, ""),
  );

  const instanceLabelMap: Map<number, string> = useMemo(() => {
    const labelMap = new Map(
      cluster?.workersExceptUnprovisionable.map((workerProcess) => {
        return [
          workerProcess?.instance?.id || 0,
          workerProcess?.instance?.privateIpAddress || "",
        ];
      }),
    );
    if (cluster?.scheduler?.instance?.id) {
      labelMap.set(cluster.scheduler.instance.id, "scheduler");
    }
    return labelMap;
  }, [cluster]);

  const getInstanceLabel = useCallback(
    (instanceId?: number) => {
      return instanceId
        ? instanceLabelMap.get(instanceId) || String(instanceId)
        : "";
    },
    [instanceLabelMap],
  );

  // should this be memo-ized? is this causing rerenders?
  const renderInstanceLabel = useCallback(
    (instanceId: number) => {
      const label = getInstanceLabel(instanceId);
      return (
        <Typography
          onMouseEnter={() => {
            setHighlightInstance(instanceId);
          }}
          onMouseLeave={() => {
            setHighlightInstance(-1);
          }}
          onClick={() => {
            setFilterPattern(`instance:${label} ${filterPattern} `.trim());
          }}
          sx={{
            fontFamily: "Roboto Mono, monospace",
            fontSize: "13px",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          {instanceId === highlightInstance ? (
            <b>
              <u>{label}</u>
            </b>
          ) : (
            label
          )}
        </Typography>
      );
    },
    [
      getInstanceLabel,
      setFilterPattern,
      highlightInstance,
      setHighlightInstance,
    ],
  );

  // DataGridPro can't correctly set the width of a column that goes outside the
  // viewport, so we'll calculate the  width of the longest message and set it as the
  // width of the column
  const messageEls = document.querySelectorAll('div[data-field="message"]>*');
  const maxMessageWith = Math.max(
    ...Array.from(messageEls).map((item) => item.clientWidth),
  );

  const Columns: GridColDef<LogEntry>[] = useMemo(
    () => [
      {
        field: "timestamp",
        headerName: `Time (${displayTz})`,
        type: "dateTime",
        valueFormatter: ({ value }) =>
          formatInTimeZone(value, displayTz, "yyyy-MM-dd HH:mm:ss.SSSS"),
        minWidth: 220,
      },
      {
        field: "instanceId",
        headerName: "Instance",
        minWidth: 120,
        // "scheduler" or private IP address for workers
        renderCell: ({ value }) => renderInstanceLabel(value),
        valueFormatter: ({ value }) => getInstanceLabel(value),
      },
      {
        field: "message",
        headerName: "Message",
        width: maxMessageWith,
        minWidth: 220,
        renderCell: (params) => {
          const message = params.row.message;
          return (
            <>
              {params.row.state && (
                <Tooltip
                  sx={{ verticalAlign: "middle" }}
                  title={params.row.stateExplanation}
                >
                  <Chip
                    size="small"
                    label={params.row.state}
                    variant={params.row.stateFilled ? `filled` : `outlined`}
                    color={params.row.stateColor || undefined}
                    sx={{
                      marginBottom: "2px",
                      marginRight: "1em",
                    }}
                  />
                </Tooltip>
              )}
              {params.row.logType === "dask" ? (
                <SyntaxHighlighter
                  language="python"
                  style={syntaxTheme}
                  customStyle={syntaxHighlighterStyle}
                >
                  {message}
                </SyntaxHighlighter>
              ) : (
                <Box sx={{ whiteSpace: "nowrap" }}>{message}</Box>
              )}
            </>
          );
        },
      },
    ],
    [renderInstanceLabel, getInstanceLabel, maxMessageWith],
  );

  const { data, isFetching, error, fetchPreviousPage } = useClusterLogs(
    clusterId,
    dask,
    system,
    timeRange,
    liveUpdates,
  );

  // much faster and less error prone than cluster logs, so for now ignoring fetch and error states
  const { data: infraEventData } = useClusterInfraEvents(
    clusterId,
    defaultLogTimeRange,
  );

  // This effect is responsible for fetching historical log data
  useEffect(() => {
    const startTimes = data?.pages.map((page) => page.timeRange[0]);
    const earliestTimestampInLogs = startTimes && Math.min(...startTimes);

    if (!timeRange || !earliestTimestampInLogs) return;

    const shouldFetchHistory = earliestTimestampInLogs > timeRange[0];

    if (shouldFetchHistory) {
      setEarliestTimestamp(earliestTimestampInLogs);

      fetchPreviousPage({
        pageParam: {
          previousTimeRange: previousTimeInterval([earliestTimestampInLogs, 0]),
        },
      });
    } else {
      // We've reached the end of the logs history
      setLogHistoryComplete(true);
    }
  }, [data?.pages, fetchPreviousPage, timeRange]);

  const logs = useMemo(() => {
    const flatLogs = (data?.pages?.flatMap((page) => page?.data) || []).concat(
      (showInfraEvents && infraEventData) || [],
    );

    const filteredLogs = filterLogs(
      flatLogs,
      instanceLabelMap,
      filterPattern,
      filterTimeRange,
      timeRange,
    );

    const dedupedLogs = dedupeLogs(filteredLogs);

    return dedupedLogs;
  }, [
    data?.pages,
    infraEventData,
    showInfraEvents,
    filterPattern,
    timeRange,
    filterTimeRange,
    instanceLabelMap,
  ]);

  const hasZoomed = timeRange !== defaultMetricsTimeRange;
  const showFilterAlert = filterPattern && !logHistoryComplete;

  const filterAlertRef = useRef<HTMLDivElement>(null);
  const filterAlertHeight = filterAlertRef.current
    ? filterAlertRef.current.clientHeight + 8 // 8px for spacing
    : 0;

  const zoomMessageRef = useRef<HTMLDivElement>(null);
  const zoomMessageHeight = zoomMessageRef.current
    ? zoomMessageRef.current.clientHeight + 8 // 8px for spacing
    : 0;

  // DataGridPro needs us to manually set the height of the grid container
  // so that virtualization works properly
  const topHeight = 220 + filterAlertHeight + zoomMessageHeight;

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box sx={{ height: "100%", p: 2, pb: 0 }}>
      <Stack spacing={1} sx={{ height: "100%", position: "relative" }}>
        {cloudProvider === BackendTypesEnum.VmAzure && (
          <Alert severity="info">
            We don’t yet support viewing Azure cluster logs in the UI. You can
            see logs using the CLI:
            <CodeBlock
              language="bash"
              snippet={`coiled cluster azure-logs --cluster ${clusterId}`}
            />
          </Alert>
        )}
        <FormGroup
          sx={() => ({
            marginBottom: "4px",
            flexDirection: "row",
          })}
        >
          <FilterField
            filterPattern={filterPattern}
            setFilterPattern={setFilterPattern}
          />

          <FormControlLabel
            control={
              <Switch
                checked={dask}
                onChange={() => setDask(!dask)}
                inputProps={{ "aria-label": "controlled" }}
                disabled={!canFetchClusterLogs}
              />
            }
            label="Dask"
          />
          <FormControlLabel
            control={
              <Switch
                checked={system}
                onChange={() => setSystem(!system)}
                inputProps={{ "aria-label": "controlled" }}
                disabled={!canFetchClusterLogs}
              />
            }
            label="System"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showInfraEvents}
                onChange={() => setShowInfraEvents(!showInfraEvents)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Lifecycle"
          />
          <div>
            <IconButton onClick={handleClickMenu}>
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={showMenu}
              onClose={handleCloseMenu}
            >
              <MenuItem>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filterTimeRange}
                      onChange={() => setFilterTimeRange(!filterTimeRange)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Filter by selected time"
                />
              </MenuItem>
            </Menu>
          </div>
        </FormGroup>
        {error ? (
          <Alert sx={{ whiteSpace: "pre-wrap" }} severity="error">
            {error?.message}
          </Alert>
        ) : null}
        {showFilterAlert ? (
          <Alert severity="info" ref={filterAlertRef}>
            Filtered results can be sparse while logs are loading.
          </Alert>
        ) : null}
        <LogsHistogram
          logs={logs}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          defaultTimeRange={
            // Charts only show the "No Data" message if the user hasn't zoomed (timeRange != defaultTimeRange), let's
            // also make that check fail when a filter pattern has been entered to avoid showing the "No Data" message
            filterPattern ? undefined : defaultMetricsTimeRange
          }
          refAreaLeft={refAreaLeft}
          refAreaRight={refAreaRight}
          setRefAreaLeft={setRefAreaLeft}
          setRefAreaRight={setRefAreaRight}
        />
        {timeRange && hasZoomed && filterTimeRange && (
          <Box sx={{ pl: 1 }} ref={zoomMessageRef}>
            <span>Showing logs between</span>{" "}
            <Chip label={timeRangeToString(timeRange)} onDelete={zoomOut} />
          </Box>
        )}
        <Box
          sx={{
            height: `calc(100vh - ${topHeight}px)`,
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              pt: 0,
              zIndex: 1,
            }}
          >
            <CircularProgress
              size={16}
              sx={{
                opacity: isFetching || !logHistoryComplete ? 1 : 0,
                verticalAlign: "text-top",
              }}
            />
            {!logHistoryComplete && earliestTimestamp ? (
              <Box
                sx={{
                  display: "inline-block",
                  ml: 1,
                }}
              >
                <span style={{ fontStyle: "italic" }}>
                  Loading logs prior to{" "}
                </span>
                <Chip
                  label={`${formatInTimeZone(
                    new Date(earliestTimestamp),
                    displayTz,
                    "LLL do, HH:mm:ss",
                  )}`}
                />
              </Box>
            ) : null}
            {timeRange && cluster ? (
              <Tooltip title="Download log range as CSV">
                <IconButton
                  onClick={() =>
                    apiRef.current.exportDataAsCsv({
                      fileName: `${new Date(
                        timeRange[0],
                      ).toISOString()}_to_${new Date(
                        timeRange[1],
                      ).toISOString()}_${cluster.name}_logs.csv`,
                    })
                  }
                >
                  <Download />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
          <DataGridPro
            apiRef={apiRef}
            getRowId={(row) => row.id ?? logs.indexOf(row)}
            density="compact"
            getRowHeight={() => "auto"}
            hideFooter
            disableRowSelectionOnClick
            sx={() => ({
              "& .MuiDataGrid-cell": {
                border: 0,
                fontFamily: "Roboto Mono, monospace",
                fontSize: "13px",
                alignItems: "flex-start",
              },
              "& .MuiDataGrid-overlay": isFetching
                ? {
                    backgroundColor: "transparent",
                  }
                : {},
            })}
            rows={logs || []}
            columns={Columns}
            initialState={{
              sorting: {
                sortModel: [{ field: "timestamp", sort: "desc" }],
              },
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

const FilterField = ({
  filterPattern,
  setFilterPattern,
}: {
  filterPattern: string | undefined;
  setFilterPattern: (filterPattern: string | undefined) => void;
}) => {
  const [localFilterPattern, setLocalFilterPattern] = useState(filterPattern);
  const debouncedFilterPattern = useDebounce(localFilterPattern, 400);

  useEffect(() => {
    setFilterPattern(debouncedFilterPattern);
  }, [setFilterPattern, debouncedFilterPattern]);

  useEffect(() => {
    if (filterPattern !== localFilterPattern) {
      setLocalFilterPattern(filterPattern);
    }
  }, [filterPattern, setLocalFilterPattern]);

  return (
    <TextField
      placeholder="Filter events"
      value={localFilterPattern}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={() => ({
        marginBottom: "4px",
        flexGrow: 1,
        paddingRight: "1rem",
      })}
      onChange={(e) => {
        setLocalFilterPattern(e.target.value || "");
      }}
      size="small"
    />
  );
};

const inRange = (timestamp: number, timeRange?: TimeRange) => {
  if (!timeRange) {
    return true;
  }
  return timestamp >= timeRange[0] && timestamp <= timeRange[1];
};

// from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
const escapeRegExp = (s: string) => {
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

const dedupeLogs = (logs: LogEntry[]) => {
  const seen = new Set<string>();
  return logs.filter((log) => {
    const key = `${log.timestamp}${log.instanceId}${log.message}`;
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
};

// Filter logs by filter pattern and time range
// TODO: Make this less loopy
const filterLogs = (
  logs: LogEntry[],
  instanceLabelMap: Map<number, string>,
  filterPattern: string,
  filterTimeRange: boolean,
  timeRange?: TimeRange,
) => {
  const logsWithId = logs.map((log, id) => ({ ...log, id }));
  if (!filterPattern) {
    return logsWithId.filter(
      (log) => !filterTimeRange || inRange(log.timestamp, timeRange),
    );
  }

  // if the filter string is "instance:123 foo", then match "123" on the instance and "foo" on the message
  const filters = filterPattern.split(" ");
  const instanceFilters = filters
    .filter((value) => value.startsWith("instance:"))
    .map((value) => value.replace("instance:", ""));
  const nonInstancePattern = filters
    .filter((value) => !value.startsWith("instance:"))
    .join(" ");
  const literalRegex = new RegExp(escapeRegExp(nonInstancePattern), "i");

  let regexRegex: RegExp | undefined;
  try {
    regexRegex = new RegExp(nonInstancePattern, "i");
  } catch {
    // the filter string isn't a valid regex, for example "[foo"
  }

  const filteredLogs = logsWithId.filter(
    (log) =>
      // match on message
      (literalRegex.test(log.message) ||
        (regexRegex && regexRegex.test(log.message)) ||
        // or on instance IP address
        (nonInstancePattern.includes(".") &&
          log.instanceId &&
          instanceLabelMap.get(log.instanceId) &&
          literalRegex.test(instanceLabelMap.get(log.instanceId) || "")) ||
        // or on state
        (log.state &&
          (literalRegex.test(log.state) ||
            (regexRegex && regexRegex.test(log.state))))) &&
      // and on instance if there's an instance filter
      (!instanceFilters.length ||
        (log.instanceId &&
          instanceLabelMap.get(log.instanceId) &&
          instanceFilters.includes(
            instanceLabelMap.get(log.instanceId) || "",
          ))) &&
      // and in time range if range is specified
      (!filterTimeRange || inRange(log.timestamp, timeRange)),
  );
  return filteredLogs;
};

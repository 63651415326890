/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UsageBreakdownItem } from './UsageBreakdownItem';
import {
    UsageBreakdownItemFromJSON,
    UsageBreakdownItemFromJSONTyped,
    UsageBreakdownItemToJSON,
} from './UsageBreakdownItem';

/**
 * 
 * @export
 * @interface ProgramUsageSchema
 */
export interface ProgramUsageSchema {
    /**
     * 
     * @type {number}
     * @memberof ProgramUsageSchema
     */
    currentCredits: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramUsageSchema
     */
    programCreditsSpent: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramUsageSchema
     */
    programCreditsUsagePercent: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramUsageSchema
     */
    programCreditsUserSpent: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramUsageSchema
     */
    programDollarsSpent: number;
    /**
     * 
     * @type {number}
     * @memberof ProgramUsageSchema
     */
    programDollarsSpendUsagePercent: number;
    /**
     * 
     * @type {Array<UsageBreakdownItem>}
     * @memberof ProgramUsageSchema
     */
    programUsageBreakdown: Array<UsageBreakdownItem>;
    /**
     * 
     * @type {Date}
     * @memberof ProgramUsageSchema
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProgramUsageSchema
     */
    endDate?: Date;
}

/**
 * Check if a given object implements the ProgramUsageSchema interface.
 */
export function instanceOfProgramUsageSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentCredits" in value;
    isInstance = isInstance && "programCreditsSpent" in value;
    isInstance = isInstance && "programCreditsUsagePercent" in value;
    isInstance = isInstance && "programCreditsUserSpent" in value;
    isInstance = isInstance && "programDollarsSpent" in value;
    isInstance = isInstance && "programDollarsSpendUsagePercent" in value;
    isInstance = isInstance && "programUsageBreakdown" in value;

    return isInstance;
}

export function ProgramUsageSchemaFromJSON(json: any): ProgramUsageSchema {
    return ProgramUsageSchemaFromJSONTyped(json, false);
}

export function ProgramUsageSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramUsageSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentCredits': json['current_credits'],
        'programCreditsSpent': json['program_credits_spent'],
        'programCreditsUsagePercent': json['program_credits_usage_percent'],
        'programCreditsUserSpent': json['program_credits_user_spent'],
        'programDollarsSpent': json['program_dollars_spent'],
        'programDollarsSpendUsagePercent': json['program_dollars_spend_usage_percent'],
        'programUsageBreakdown': ((json['program_usage_breakdown'] as Array<any>).map(UsageBreakdownItemFromJSON)),
        'startDate': !exists(json, 'start_date') ? undefined : (new Date(json['start_date'])),
        'endDate': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'])),
    };
}

export function ProgramUsageSchemaToJSON(value?: ProgramUsageSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_credits': value.currentCredits,
        'program_credits_spent': value.programCreditsSpent,
        'program_credits_usage_percent': value.programCreditsUsagePercent,
        'program_credits_user_spent': value.programCreditsUserSpent,
        'program_dollars_spent': value.programDollarsSpent,
        'program_dollars_spend_usage_percent': value.programDollarsSpendUsagePercent,
        'program_usage_breakdown': ((value.programUsageBreakdown as Array<any>).map(UsageBreakdownItemToJSON)),
        'start_date': value.startDate === undefined ? undefined : (value.startDate.toISOString().substring(0,10)),
        'end_date': value.endDate === undefined ? undefined : (value.endDate.toISOString().substring(0,10)),
    };
}


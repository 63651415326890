/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogPageSessionRequestSchema
 */
export interface LogPageSessionRequestSchema {
    /**
     * 
     * @type {string}
     * @memberof LogPageSessionRequestSchema
     */
    encodedSession?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LogPageSessionRequestSchema
     */
    dask?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LogPageSessionRequestSchema
     */
    system?: boolean;
}

/**
 * Check if a given object implements the LogPageSessionRequestSchema interface.
 */
export function instanceOfLogPageSessionRequestSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LogPageSessionRequestSchemaFromJSON(json: any): LogPageSessionRequestSchema {
    return LogPageSessionRequestSchemaFromJSONTyped(json, false);
}

export function LogPageSessionRequestSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogPageSessionRequestSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'encodedSession': !exists(json, 'encoded_session') ? undefined : json['encoded_session'],
        'dask': !exists(json, 'dask') ? undefined : json['dask'],
        'system': !exists(json, 'system') ? undefined : json['system'],
    };
}

export function LogPageSessionRequestSchemaToJSON(value?: LogPageSessionRequestSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'encoded_session': value.encodedSession,
        'dask': value.dask,
        'system': value.system,
    };
}


import React, { useEffect } from "react";
import { SimpleTable } from "../../shared-components/SimpleTable";
import { formatCurrency } from "../../javascriptSucksUtils";
import {
  useProgram,
  useProgramUsage,
  useUpdateLimitsMutation,
} from "../../crud/pricing/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  BillingMethod,
  FeatureTier,
  ProgramSchema,
  UsageBreakdownItem,
} from "../../api-client";
import { InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { HookSwitch } from "../../shared-components/HookSwitch";

type ProgramTableProps = {
  organizationId: number;
};

const tierToFancyName = (tier?: FeatureTier): string => {
  switch (tier) {
    case FeatureTier.Essentials:
      return "Coiled Essentials";
    case FeatureTier.Professional:
      return "Coiled Professional";
    default:
      return "";
  }
};

export const ProgramTable = ({
  organizationId,
}: ProgramTableProps): React.ReactElement | null => {
  const { data: activeProgram, isSuccess: programSuccess } =
    useProgram(organizationId);
  const { data: programUsage, isSuccess: programUsageSuccess } =
    useProgramUsage(organizationId);
  const updateLimitsMutation = useUpdateLimitsMutation();
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
    watch,
  } = useForm<ProgramSchema>({
    defaultValues: {
      id: 0,
      spendLimitShouldStopInstances: true,
      programDollarsSpendLimit: 0,
      organizationCreditLimit: 0,
    },
  });
  const programDollarsSpendLimit = watch("programDollarsSpendLimit");
  const organizationCreditLimit = watch("organizationCreditLimit");
  useEffect(() => {
    if (activeProgram) {
      reset(activeProgram);
    }
  }, [activeProgram, reset]);
  const onSubmit: SubmitHandler<ProgramSchema> = (data: ProgramSchema) => {
    updateLimitsMutation.mutate(
      {
        organizationId: data.id,
        updateLimitsSchema: {
          spendLimitShouldStopInstances: data.spendLimitShouldStopInstances,
          spendLimit: data.programDollarsSpendLimit,
          organizationCreditLimit: data.organizationCreditLimit || 0,
        },
      },
      {
        onSuccess: () => {
          reset(data);
        },
      },
    );
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <SimpleTable
          loading={!programSuccess || !programUsageSuccess}
          text={[
            ["Current Plan", tierToFancyName(activeProgram?.tier)],
            [
              "Credit Balance",
              programUsage?.currentCredits.toLocaleString() || "0",
            ],
            ...(programUsage?.programUsageBreakdown.map(
              (value: UsageBreakdownItem): [string, string] => [
                `${value.name} Hours Used`,
                `${value.hours.toLocaleString()} (${value.price.toLocaleString()} ${value.price.toLocaleString() === "1" ? "credit" : "credits"} per hour)`,
              ],
            ) || []),
            [
              "Coiled Credits Used",
              programUsage?.programCreditsSpent.toLocaleString() || "0",
            ],
            [
              "Coiled Credit Monthly Allowance",
              activeProgram?.creditAllotment.toLocaleString() || "",
            ],
            [
              "Coiled Credits Allowance Used",
              `${
                programUsage?.programCreditsUsagePercent.toLocaleString() || "0"
              }%`,
            ],
            [
              "Coiled Credit Value",
              activeProgram
                ? formatCurrency(activeProgram.coiledCreditValue) || "0.05"
                : "",
            ],
            [
              "Current Monthly Spend",
              formatCurrency(programUsage?.programDollarsSpent) || "0",
            ],

            [
              "Dollar Spend Limit",
              activeProgram?.billingMethod !== BillingMethod.Empty ? (
                <TextField
                  key="dollar-spend-limit"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {programDollarsSpendLimit === 0
                          ? "(zero means no limit)"
                          : ""}
                        $
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  size="small"
                  {...register("programDollarsSpendLimit", {
                    valueAsNumber: true,
                  })}
                />
              ) : (
                "N/A"
              ),
            ],
            [
              "Credit Spend Limit",
              <TextField
                key="credit-spend-limit"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {organizationCreditLimit === 0
                        ? "(zero means no limit)"
                        : ""}
                    </InputAdornment>
                  ),
                }}
                type="number"
                size="small"
                {...register("organizationCreditLimit", {
                  valueAsNumber: true,
                })}
              />,
            ],
            [
              "Spend Limits Should Stop Instances",
              <Tooltip
                key="spend-limit-tooltip"
                title={
                  "This setting controls whether any running instances will be stopped as soon as limits (organization, workspace, or user) are hit. If we do not stop running instances, this will result in charges beyond your configured spend limits. Creating new clusters will always be prevented once spend limit is hit."
                }
              >
                <HookSwitch
                  key="spend-limit-switch"
                  control={control}
                  name="spendLimitShouldStopInstances"
                />
              </Tooltip>,
            ],
          ]}
        />
        <LoadingButton
          type="submit"
          variant="primary"
          disabled={!isDirty}
          sx={{ maxWidth: "100px" }}
        >
          Save
        </LoadingButton>
      </Stack>
    </form>
  );
};

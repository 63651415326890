export enum BannerLevel {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

export type Banner = {
  id: number;
  start: Date;
  end: Date;
  title: string;
  message: string;
  level: BannerLevel;
};
